.dropdownImage{
    width: 40px;

}
.dropupImage{
    width: 40px;
    
}
.spanBox{
    height: 20px;
    align-items: center;
}
.edit_button{
    border-radius: 5px;
    margin-right: 5px;
}

h2 {
    padding-top: 1vw
}

