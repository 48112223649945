.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.groupItem {
    border-style: solid;
    border-width: 2px;
    border-color: #CFD5E8;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    filter: drop-shadow(3px 3px rgba(0,0,0,0.3));
    cursor:pointer;
}

.groupList {
    position: relative;
    padding-right: 5.3%;
}

h3 {
    text-align: left;
}

#delete {
    margin-left: 10px;
}

#addButton{
    margin-left: 30px;
}

.squareBttn{
    border-radius: 5px;
}