.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.item {
    border-style: solid;
    border-width: 2px;
    border-color: #CFD5E8;
    border-radius: 10px;
    padding-left: 10px;
    background-color: white;
    filter: drop-shadow(3px 3px rgba(0,0,0,0.3));
}

h2 {
    text-align: left;
}

#edit {
    margin: 10px;
}

.contacts {
    position: relative;
    padding-right: 5.3%;
    padding-bottom: 20px;
}
.squareBttn{
    border-radius: 5px;
}