.overall-average {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid;
    border-radius: 100%;
    width: 150px;
    height: 150px;
    font-size: 3em;
    color: #9a1d22;
    margin: auto;
}
