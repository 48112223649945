h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
  text-align: center;
}

h3 {
  margin-top: 0;
  font-size: 18px;
  text-align: center;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

.navbar{
  position: fixed;
  width: 200px;

}

.admin_Card {
  text-align: center;
  float: left;
  filter: drop-shadow(3px 3px rgba(0, 0, 0, 0.3));
}

.list {
margin: 20px;
border-style: solid;
border-width: 10px 5px 20px;
border-color: #CFD5E8;
border-radius: 10px;
background-color: white;
display: inline-grid;
float: left;
}

.clientName {
background-color: #CFD5E8;
text-align: left;
}

.impactScore {
color: #9a1d22;
margin-left: 14%;
}

#number {
  text-align: center;
  border: solid;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  line-height: 150px;
  font-size: 3em;
}

.LIS {
padding: 10px 30px;
text-align: center;
}

.wrapper {
text-align: left;
color: #120957;
font-family: Rufina;
}

.about_link{
  position: absolute;
  bottom: 0%;
}

.filterContainer{
  padding-right: 4.4%;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.filter {
  margin-left: 7px;
  border: solid;
  border-width: 3px;
  border-color: #CFD5E8;
  background-color: white;
}

.LIShead {
  text-align: center;
}

#tempButton {
  right: 0;
  bottom: 0;
  position:absolute;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}