.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.form {
    text-align: left;
    padding: 20px;
}

.spacer {
    padding-bottom: 20px;
}