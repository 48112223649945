.survey-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f0f0f0;
}

.survey-form {
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.heading {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

div.question {
    text-align: left;
    margin-bottom: 20px;
}

div.question p {
    font-weight: bold;
    margin-bottom: 10px;
}

textarea, input[type="radio"] + label {
    display: block;
    width: 100%;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input[type="radio"] {
    margin-right: 10px;
    vertical-align: middle;
}

input[type="radio"] + label {
    display: inline-block;
    width: auto;
    margin: 5px 0;
    padding: 5px;
    vertical-align: middle;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.survey-button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.submit-message {
    text-align: center;
}
