.page {
    color: #120957;
    font-family: Rufina;
    text-align: center;
}

h1 {
    font-size: 28px;
    position: relative;
    padding-right: 5.6%;
}

.back {
    margin: 20px;
}

.inputField {
    display: flex; /* Makes the container a flexbox */
    align-items: center; /* Align items vertically in the center */
    justify-content: start; /* Align items to the start of the flexbox */
    padding: 7px;
    gap: 10px; /* Adds some space between the label and input */
}

.container {
    display: flex;
    justify-content: center;
}

.buttons {
    position: relative;
    padding-right: 6.8%;
}

#usernameLabel{
    padding-right: 10%;
}

#password{
    margin-left:2%;
}
#passwordLabel{
    padding-left: 7%;
    padding-right: 3%;
}


img {
    width: 300px;
    position: relative;
    padding-right: 5.3%;
}

.error-message {
    font-size: small;
    color: #9a1d22;
}