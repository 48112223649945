.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

/* .panel {
    display: flex;
    justify-content: left;
} */

h1 {
    text-align: left;
}

.input-container{

}

.formLable {
    color: #120957; /* Adjust the color to match the second image */
    display: block; /* Make labels block to have them above inputs */
    margin-bottom: .5rem;
    font-weight: bold;
    float: left;
}

.formInput {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: .5rem;
    box-sizing: border-box;
}

#saveButton {
    width: auto;
    padding: .5rem 2rem;
    margin-top: 1rem;
    float: none; /* Avoid floating to ensure it stays in the form flow */
    display: block; /* Optional: to have it on a new line */
    margin-left: auto; /* Optional: to align it to the right */
    border-radius: 5px;
}

#MIInput {
    float: left;
}