.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.selType {
    margin: 0px 10px;
    padding: 0px 3px;
}

#delete {
    padding: 3px 9px;
}

p {
    text-align: left;
}

select {
    margin-left: 7px;
    border: solid;
    border-width: 3px;
    border-color: #CFD5E8;
    background-color: white;
}
.squareBttn{
    border-radius: 5px;
}