.signup {
    text-align: center;
    color: #120957;
    font-family: Rufina;
    padding-left: 5%;
}

.form {
    text-align: left;
    padding: 20px;
    padding-bottom: 0px;
    margin: 2px;
}

.formContent {
    display: flex;
    justify-content: center;
    padding-right: 2%;
    padding-bottom: 10px;
    margin: 10vw;
}

.spacing {
    padding-bottom: 10px;
}

.buttons {
    padding-right: 6.6%;
}

.login {
    margin-left: 10px;
}