.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
    }

.head {
    text-align: center;
}

.about-content {
    text-align: center;
}

