.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.template {
    border-style: solid;
    border-width: 2px;
    border-color: #CFD5E8;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    filter: drop-shadow(3px 3px rgba(0,0,0,0.3));
}

.tempList {
    position: relative;
    padding-right: 5.3%;
    padding-bottom: 20px;
}

#addNew {
    position: sticky;
    margin-left: 10px;
}

#delete {
    margin-left: 10px;
}

button {
    background-color: #432FB2;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 3px 10px;
}

.squareBttn{
    border-radius: 5px;
}
