.navbar{
    position: fixed;
    width:200px;
    height:100vh;
    background-color: #f0f0f0;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding:20px;
}

.logo {
    margin-bottom:20px;
    text-align: center;
    border: 2px solid black;
}

ul {
    list-style-type: none;
    padding:0;
    margin:0;
    flex:1;
}

ul li {
    margin-bottom:20px;
}

.about-link {
    margin-bottom:0;
}
* {
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
    margin: 10px;
    padding: 0;
  }
  
  h1 {
    margin-top: 0;
    font-size: 22px;
  }
  
  h2 {
    margin-top: 0;
    font-size: 20px;
    text-align: center;
  }
  
  h3 {
    margin-top: 0;
    font-size: 18px;
    text-align: center;
  }
  
  h4 {
    margin-top: 0;
    font-size: 16px;
  }
  
  h5 {
    margin-top: 0;
    font-size: 14px;
  }
  
  h6 {
    margin-top: 0;
    font-size: 12px;
  }
  
  code {
    font-size: 1.2em;
  }
  
  ul {
    padding-inline-start: 20px;
  }
  
  h3, p { 
    margin: 5px 0px; 
    text-align: center;
  }
  
  .navbar{
    position: fixed;
    width: 200px;

  }
  .survey_card{
    position: relative; left: 200px;
    width: 350px;
    padding: 10px;
    border: 1px solid #aaa;
    margin-left: 20px;
    float: left;
    display: grid;
    margin-top: 50px;
  }
  .panel {
    border: 1px solid #aaa;
  }

  .about_link{
    position: absolute;
    bottom: 0%;
  }

  .filterContainer{
    position: absolute;
    right: 0%;
  }
  #impact{
    font-size: 90px;
    text-align: center;
    font-weight: 900;
  }