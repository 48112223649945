.wrapper {
    position: absolute;
    top: 70px; 
    right: 0;
    width: calc(100% - 300px);
    height: calc(100% - 70px);
    background-color: white;
    overflow: auto;
}

.accordion {
    background-color: white;
    margin-top: 10px;
    padding-right: 20px;
}

.item {
    background-color: white;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-top: 5px;
    margin-right: 20px;
    padding: 0px 20px;
    border-style: solid;
    border-width: 2px;
    border-color:#CFD5E8;
    border-radius: 5px;
    filter: drop-shadow(3px 3px rgba(0,0,0,0.3));
    
}

.title {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    cursor: pointer;
}

.content {
    background-color: white;
    max-height: 0px;
    opacity: 0;
    display:none;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1), opacity 0.5s linear;
    padding-bottom: 5px;
    
}

.content.show {
    opacity: 1;
    display:block;
    max-height: 9999px; /* Consider adjusting this value if possible */
    transition: all 0.5s cubic-bezier(1,0,1,0), opacity 0.5s linear;
    
}

.dropdownIcons {
    height: 20px;
    width: 20px;
    margin-top: 5px;
}

.test {
    height: 70px;
    width: 120px;
    margin-top: 4px;
}

img {
    background-color: transparent
}