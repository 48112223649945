.top-bar {
    background-color: #120957;
    color: #fff;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
}

.top-bar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.spacing {
    font-size: 1.5rem;
}

.username-display {
    font-size: 1rem;
    font-family: Rufina;
    display: flex;
    align-items: center;
    gap: 5px;
}

.person-icon {
    display: flex;
    align-items: center;
}

.link-icon {
    color: inherit;         /* Keeps the text color from changing */
    text-decoration: none;  /* Removes underline */
}

.link-icon img {
    filter: none;           /* Removes any filters that might be adding hue */
}