.wrapper {
    padding: 20px;
  }
  
  .multiple-choice-chart {
    margin-bottom: 40px;
  }
  
  .question {
    margin-bottom: 20px;
  }
  
  .question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .guidance {
    margin-top: 20px;
  }
  
  .guidance-textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  