.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}
.squareBttn{
    border-radius: 5px;
}

#newSurvey{
    float: right;
    width: 83%;
    margin-right: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    height:625px;
    overflow:auto;
}

#number2 {
    text-align: center;
    border: solid;
    border-radius: 100%;
    width: 150px;
    height: 150px;
    line-height: 150px;
    font-size: 3em;
}

#templateName {
    background-color: #CFD5E8;
    padding-bottom: 0px;
}

.display {
    display: inline-grid;
    float: left;
}

.list2 {
    margin: 20px;
    border-style: solid;
    border-width: 10px 5px 10px;
    border-color: #CFD5E8;
    border-radius: 10px;
    background-color: white;
    display: inline-grid;
    float: left;
}

.date {
    background-color: #CFD5E8;
    padding-top: 10px;
    text-align: left;
}