.wrapper {
    text-align: left;
    color: #120957;
    font-family: Rufina;
}

.question {
    padding: 15px;
}

h1 {
    font-size: 28px;
}

form {
    border-style: solid;
    border-width: 2px;
    border-color: #CFD5E8;
    padding: 10px;
    margin-right: 5%;
    border-radius: 10px;
    text-align: center;
}

.tempName {
    font-size: 24px;
}

.quesType, .scale {
    margin: 10px;
}

.squareBttn{
    border-radius: 5px;
}