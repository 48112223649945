.App {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

.Sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  height: 100vh;
  width: 250px;
  background-color: #120957;
  z-index: 5;
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  flex-grow: 1;
}

.SidebarList .row {
  width: 100%;
  height: 60px;
  background-color: #120957;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.logout {
  background-color: #2C003E;
}

.logout:hover {
  background-color: #432FB2;
}

.SidebarList .row:hover {
  cursor: pointer;
  background-color: #432FB2;
}

.SidebarList #active {
  background-color: #432FB2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(50000deg);
  }
}


#App {
  display:flex;
  justify-content: center;
  align-items: center;
}

#App img {
  display:flex;
  width:100%;
}

.row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.row #title {
  flex: 70%;
}